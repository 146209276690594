.book-list-item-container {
  margin: 12px;
  margin-right: 25px;
  position: relative;
}
.book-item-list-cover {
  width: 43px;
  /* height: 74px; */
  opacity: 1;
  cursor: pointer;
  /* overflow: hidden; */
  border-radius: 1px;
  display: inline-block;
  margin-right: 12px;
}

.book-item-list-title {
  display: inline-block;
  width: calc(100% - 80px);
  height: 100%;
  position: absolute;
  top: 0px;
  left: 60px;
}

.book-item-list-author {
  width: 150px;
  float: right;
}
.book-item-list-percentage {
  width: 50px;
  float: right;
}
.book-item-list-subtitle,
.book-item-list-author,
.book-item-list-percentage {
  height: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-height: 18px;
  font-size: 15px;
  display: flex;
  align-items: center;
}
.book-item-list-subtitle {
  width: calc(100% - 250px);
  float: left;
}
.book-item-list-subtitle-text {
  float: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
.book-item-list-config {
  cursor: pointer;
}
.list-icon {
  margin: 15px 10px;
  font-size: 20px;
}
