.trans-container {
  width: 264px;
  height: 172px;
}
.original-text-box,
.trans-text-box {
  width: 230px;
  height: 100px;
  opacity: 1;
  border-radius: 5px;
  position: absolute;
  user-select: text;
  overflow-y: scroll;
  padding: 5px;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 18px;
}
.trans-button-container {
  position: absolute;
  bottom: 8px;
  right: 20px;
}
.trans-text-box {
  left: 16px;
  top: 16px;
}
.target-lang-container {
  position: absolute;
  width: calc(100% - 32px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 16px;
  left: 16px;
}
.dict-url {
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
}
.trans-container .r0bn4c {
  font-weight: bold;
}
.trans-container .r0bn4c::before {
  content: "[";
  font-weight: bold;
}
.trans-container .r0bn4c::after {
  content: "]";
  font-weight: bold;
}
.trans-container ol {
  counter-reset: my-counter;
  list-style-type: none;
}

.trans-container li:before {
  counter-increment: my-counter;
  content: counter(my-counter) ". ";
  font-weight: bold;
}
.trans-container li div {
  display: inline;
}
